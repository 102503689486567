
import Vue from "vue";
import ModalTask from "./ModalTask.vue";
import ModalConfirmDeleteCustomer from "./ModalConfirmDeleteCustomer.vue";
import api from "@/api/customer";
import { mapActions } from "vuex";
import { CustomerActionTypes } from "@/store/customers/action-types";
import { Success, Warn } from "@/services/notif-utils";
const columns = [
  {
    name: "name",
    field: "name",
    label: "Name",
    align: "left",
  },
  {
    name: "vip_response",
    field: "vip_response",
    label: "VIP Response",
    align: "center",
  },
  {
    name: "non_vip_response",
    field: "non_vip_response",
    label: "Non VIP Response",
    align: "center",
  },
  {
    name: "tool",
    field: "tool",
    label: "Tool",
    align: "center",
  },
  {
    name: "actions",
    field: "actions",
    label: "Actions",
  },
];
export default Vue.extend({
  name: "TasksTable",
  components: {
    ModalTask,
    ModalConfirmDeleteCustomer,
  },
  data() {
    return {
      loading: false,
      columns: columns,
      openModal: false,
      selectedTask: null,
      confirmDialog: false,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsNumber: 20,
        rowsPerPage: 20,
      },
    };
  },
  computed: {
    tasks(): any {
      return this.$store.state.Customer.tasks;
    },
    tasksLoaded(): any {
      return this.$store.state.Customer.tasksLoaded;
    },
  },
  methods: {
    ...mapActions({
      loadTasksAction: "Customer/" + CustomerActionTypes.LOAD_TASKS,
      deleteTaskAction: "Customer/" + CustomerActionTypes.DELETE_TASK,
    }),
    loadTasks() {
      this.loading = true;
      this.loadTasksAction()
        .then((responseData: any) => {
          this.initialPagination.rowsNumber = responseData.count;
          this.loading = false;
          Success("Loaded Tasks!");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    async paginator(props: any) {
      this.initialPagination.page = props.pagination.page;
      await this.loadTasks();
    },
    viewTasks(task: any) {
      console.log(task);
      this.selectedTask = task;
      this.openModal = true;
    },
    confirmDelete(task: any) {
      this.selectedTask = task;
      this.confirmDialog = true;
    },
    closeTaskModal() {
      this.openModal = false;
      this.selectedTask = null;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.selectedTask = null;
    },
    deleteTask() {
      const task = this.selectedTask as any;
      if (task) {
        this.deleteTaskAction(task.id)
          .then(() => {
            Success("Deleted Task!");
            this.selectedTask = null;
            this.confirmDialog = false;
          })
          .catch((error) => {
            console.log(error);
            Warn("Unable to Delete Task!");
          });
      }
    },
  },
  created() {
    if (!this.tasksLoaded) {
      this.loadTasks();
    }
  },
});
