
import Vue from "vue";
import ModalTool from "./ModalTool.vue";
import ModalConfirmDeleteCustomer from "./ModalConfirmDeleteCustomer.vue";
import { mapActions } from "vuex";
import { CustomerActionTypes } from "@/store/customers/action-types";
import { Info, Success, Warn } from "@/services/notif-utils";
const columns = [
  {
    name: "name",
    field: "name",
    label: "name",
    align: "left",
  },
  {
    name: "actions",
    field: "actions",
    label: "Actions",
    align: "center",
  },
];
export default Vue.extend({
  name: "ToolsTable",
  components: {
    ModalTool,
    ModalConfirmDeleteCustomer,
  },
  data() {
    return {
      loading: false,
      columns: columns,
      selectedTool: null,
      confirmDialog: false,
      openModal: false,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsNumber: 20,
        rowsPerPage: 20,
      },
    };
  },
  computed: {
    tools(): any {
      return this.$store.state.Customer.tools;
    },
    toolsLoaded(): boolean {
      return this.$store.state.Customer.toolsLoaded;
    },
  },
  methods: {
    ...mapActions({
      loadToolsAction: "Customer/" + CustomerActionTypes.LOAD_TOOLS,
      deleteToolAction: "Customer/" + CustomerActionTypes.DELETE_TOOL,
    }),
    loadTools() {
      this.loading = true;
      this.loadToolsAction()
        .then((responseData: any) => {
          this.initialPagination.rowsNumber = responseData.count;
          this.loading = false;
          Success("Loaded Tools!");
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async paginator(props: any) {
      this.initialPagination.page = props.pagination.page;
      await this.loadTools();
    },
    viewTools(tool: any) {
      this.selectedTool = tool;
      this.openModal = true;
    },
    confirmDelete(tool: any) {
      this.selectedTool = tool;
      this.confirmDialog = true;
    },
    closeToolModal() {
      this.openModal = false;
      this.selectedTool = null;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.selectedTool = null;
    },
    deleteTool() {
      this.loading = true;
      const tool = this.selectedTool as any;
      if (tool) {
        this.deleteToolAction(tool.id)
          .then(() => {
            Info("Tool Deleted!");
            this.loading = false;
            this.confirmDialog = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            Warn("Unable to Delete Tool.");
          });
        return;
      }
      Warn("No Tool To Delete");
    },
  },
  created() {
    if (!this.toolsLoaded) {
      this.loadTools();
    }
  },
});
