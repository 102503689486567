
import Vue from "vue";
// import ModalVIPCustomer from "./ModalVIPCustomer.vue";
import VIPCustomersTable from "./VIPCustomersTable.vue";
import api from "@/api/customer";
import { Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "CustomerPanel",
  components: {
    // ModalVIPCustomer,
    VIPCustomersTable,
  },
  data() {
    return {
      loading: false,
      openModal: false,
    };
  },
  methods: {
    loadCustomers() {
      api
        .loadCustomers()
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          Warn("Failed to Load Customers");
          console.log(err);
        });
    },
    loadTasks() {
      api
        .loadTasks()
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          Warn("Failed to Load Customers");
          console.log(err);
        });
    },
  },
  created() {
    // this.loadTasks();
  },
});
