
import { Emitter } from "@socket.io/component-emitter";
import Vue from "vue";
export default Vue.extend({
  name: "ConfirmDelete",
  props: ["show"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    toggleShow(): boolean {
      return this.show;
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("close");
    },
  },
});
