
import Vue from "vue";
import ToolSTable from "./ToolSTable.vue";
export default Vue.extend({
  name: "ToolPanel",
  components: {
    ToolSTable,
  },
  data() {
    return {
      loading: false,
    };
  },
});
