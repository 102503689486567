
import { CustomerMutationTypes } from "@/store/customers/mutation-types";
import Vue from "vue";
import ModalVIPCustomer from "./ModalVIPCustomer.vue";
import ModalConfirmDeleteCustomer from "./ModalConfirmDeleteCustomer.vue";
import { mapActions } from "vuex";
import { CustomerActionTypes } from "@/store/customers/action-types";
import { Info, Success, Warn } from "@/services/notif-utils";
const columns = [
  {
    name: "discogs_username",
    field: "discogs_username",
    label: "Discogs Username",
    align: "left",
  },
  {
    name: "discogs_email",
    field: "discogs_email",
    label: "Discogs Email",
    align: "center",
  },
  {
    name: "kupiku_username",
    field: "kupiku_username",
    label: "Kupiku Username",
    align: "center",
  },
  {
    name: "kupiku_email",
    field: "kupiku_email",
    label: "Kupiku Email",
    align: "center",
  },
  {
    name: "ebay_username",
    field: "ebay_username",
    label: "Ebay Username",
    align: "center",
  },
  {
    name: "is_vip",
    field: "is_vip",
    label: "VIP Status",
    align: "center",
  },
  {
    name: "tasks",
    field: "tasks",
    label: "Running Services",
    align: "center",
  },
  {
    name: "actions",
    label: "Actions",
    align: "center",
  },
];
export default Vue.extend({
  name: "CustomersTable",
  components: {
    ModalVIPCustomer,
    ModalConfirmDeleteCustomer,
  },
  props: ["data"],
  data() {
    return {
      columns: columns,
      loading: false,
      openModal: false,
      selectedCustomer: null,
      confirmDialog: false,
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsNumber: 20,
        rowsPerPage: 20,
      },
    };
  },
  computed: {
    customers(): any {
      return this.$store.state.Customer.customers;
    },
    customerLoaded(): any {
      return this.$store.state.Customer.customersLoaded;
    },
  },
  methods: {
    ...mapActions({
      loadCustomersAction: "Customer/" + CustomerActionTypes.LOAD_CUSTOMERS,
      deleteCustomerAction: "Customer/" + CustomerActionTypes.DELETE_CUSTOMER,
    }),
    viewCustomer(customer: any) {
      this.selectedCustomer = customer;
      this.openModal = true;
    },
    getTotalTasks(tasks: any) {
      if (tasks.length > 0) {
        return tasks.length;
      }
      return 0;
    },
    confirmDelete(customer: any) {
      this.selectedCustomer = customer;
      this.confirmDialog = true;
    },
    deleteCustomer() {
      const customer = this.selectedCustomer as any;
      if (customer) {
        this.deleteCustomerAction(customer.id)
          .then(() => {
            Success("Deleted Customer");
            this.selectedCustomer = null;
            this.confirmDialog = false;
          })
          .catch((error) => {
            console.log(error);
            Warn("Unable to Delete Customer");
          });
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.selectedCustomer = null;
    },
    closeCustomerModal() {
      this.openModal = false;
      this.selectedCustomer = null;
    },
    async paginator(props: any) {
      this.initialPagination.page = props.pagination.page;
      await this.loadCustomers();
    },
    loadCustomers() {
      this.loading = true;
      this.loadCustomersAction()
        .then((responseData: any) => {
          this.initialPagination.rowsNumber = responseData.count;
          this.loading = false;
          Success("Loaded Customers!");
          if (responseData) {
            const dataCount = responseData.count;
            if (dataCount == 0) {
              Info("No customers Set yet! Feel free to start!");
            }
          }
        })
        .catch((error) => {
          const errorResponse = error.response;
          if (!errorResponse) {
            Info("Unable to reach Shipping Server.");
          }
          if (errorResponse.status == 404) {
            Info(
              "Not Found: Error 404... Basically Can't find the resource as asked"
            );
          }
          if (errorResponse.status == 500) {
            Info("Server Error. Something went wrong in the Shipsite server");
          }
          this.loading = false;
          Warn("Unable to Load Customers");
        });
    },
  },
  created() {
    if (!this.customerLoaded) {
      this.loading = true;
      this.loadCustomers();
    }
  },
});
