
import Vue from "vue";
import CustomerPanel from "@/components/CustomerPanel.vue";
import TasksPanel from "@/components/TasksPanel.vue";
import ToolPanel from "@/components/ToolPanel.vue";

export default Vue.extend({
  name: "VIPCustomers",
  components: {
    CustomerPanel,
    TasksPanel,
    ToolPanel,
  },
  data() {
    return {
      tab: "customers",
    };
  },
});
