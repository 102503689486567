import Util from "./util";
const axios = Util.createAxiosInstance("http://0.0.0.0:8002/customers/api/");
// axios.defaults.headers;
export default {
  createCustomer(payload: any) {
    return new Promise((resolve, reject) => {
      const url = "/customer/";
      const kc = localStorage.getItem("kcToken");
      axios
        .post(url, payload, { headers: { Authorization: `Bearer ${kc}` } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadCustomers() {
    return new Promise((resolve, reject) => {
      const url = `/customer/`;
      const kc = localStorage.getItem("kcToken");
      axios
        .get(url, { headers: { Authorization: `Bearer ${kc}` } })
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  loadTasks() {
    return new Promise((resolve, reject) => {
      const url = `/task`;
      const kc = localStorage.getItem("kcToken");
      axios
        .get(url, { headers: { Authorization: `Bearer ${kc}` } })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  createTask() {
    return new Promise((resolve, reject) => {
      const url = `/task`;
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTool() {
    return new Promise((resolve, reject) => {
      const url = `/tool`;
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadTools() {
    return new Promise((resolve, reject) => {
      const url = `/tool`;
      const kc = localStorage.getItem("kcToken");
      axios
        .get(url, { headers: { Authorization: `Bearer ${kc}` } })
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};
