
import Vue from "vue";
import TasksTable from "./TasksTable.vue";

export default Vue.extend({
  name: "TasksPanel",
  components: { TasksTable },
  data() {
    return {
      loading: false,
    };
  },
});
