
import { Success, Warn } from "@/services/notif-utils";
import { CustomerActionTypes } from "@/store/customers/action-types";
import { relativeTimeThreshold } from "moment";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "ToolModal",
  props: ["show", "tool"],
  data() {
    return {
      loading: false,
      data: {
        id: null,
        name: "",
      },
      isEdit: false,
    };
  },
  computed: {
    toggleState(): boolean {
      return this.show;
    },
  },
  methods: {
    ...mapActions({
      createToolAction: "Customer/" + CustomerActionTypes.CREATE_TOOL,
      updateToolAction: "Customer/" + CustomerActionTypes.UPDATE_TOOL,
    }),
    setDetails() {
      if (this.tool) {
        this.isEdit = true;
        const loadedTool = this.tool;
        this.data = {
          id: loadedTool["id"],
          name: loadedTool["name"],
        };
      }
    },
    close() {
      this.$emit("close");
    },
    reset() {
      return (this.data = {
        id: null,
        name: "",
      });
    },
    updateTool() {
      if (!this.data) {
        return Warn("Unable to Update Tool, no Tool Set");
      }
      this.updateToolAction(this.data)
        .then(() => {
          Success("Tool Updated!");
          this.loading = false;
          // this.reset()
          // this.close()
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable To Update Tool");
          this.loading = false;
        });
    },
    createTool() {
      if (!this.data) {
        return Warn("No Tool to Save");
      }
      this.createToolAction(this.data)
        .then(() => {
          Success("Tool Saved!");
          this.loading = false;
          this.reset();
          this.close();
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to Save Tool.");
          this.loading = false;
        });
    },
    saveTool() {
      this.loading = true;
      if (this.isEdit) {
        return this.updateTool();
      }
      return this.createTool();
    },
  },
  created() {
    this.setDetails();
  },
});
