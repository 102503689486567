
/* eslint-disable camelcase */
import Vue from "vue";
import { CustomerMutationTypes } from "@/store/customers/mutation-types";
import { Info, Success, Warn } from "@/services/notif-utils";
import { mapActions } from "vuex";
import { CustomerActionTypes } from "@/store/customers/action-types";

export default Vue.extend({
  name: "TaskModal",
  props: ["show", "task"],
  data() {
    return {
      loading: false,
      data: {
        id: null,
        name: "",
        vip_response: false,
        non_vip_response: false,
        tool: null,
      },
      isEdit: false,
      loadingTools: false,
    };
  },
  computed: {
    tasks(): any {
      return this.$store.state.Customer.tasks;
    },
    toggleState(): boolean {
      return this.show;
    },
    tools(): any {
      return this.$store.state.Customer.tools;
    },
    toolsLoaded(): any {
      return this.$store.state.Customer.toolsLoaded;
    },
  },
  methods: {
    ...mapActions({
      loadToolsAction: "Customer/" + CustomerActionTypes.LOAD_TOOLS,
      updateTaskAction: "Customer/" + CustomerActionTypes.UPDATE_TASK,
      createTaskAction: "Customer/" + CustomerActionTypes.CREATE_TASK,
    }),
    setDetails() {
      if (this.task) {
        this.isEdit = true;
        const loadedTask = this.task;
        this.data = {
          id: loadedTask["id"],
          name: loadedTask["name"],
          vip_response: loadedTask["vip_response"],
          non_vip_response: loadedTask["non_vip_response"],
          tool: loadedTask["tool"]["id"],
        };
      }
    },
    close() {
      this.$emit("close");
    },
    reset() {
      return (this.data = {
        id: null,
        name: "",
        vip_response: false,
        non_vip_response: false,
        tool: null,
      });
    },
    updateTask(data: any) {
      this.updateTaskAction(data)
        .then(() => {
          Success("Successfully Updated Task!");
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          Warn("Unable to Update Task");
        });
    },
    createTask() {
      if (!this.data) {
        return Info("No data to Save");
      }
      this.createTaskAction(this.data)
        .then(() => {
          Success("Task Saved!");
          this.loading = false;
          this.reset();
          this.close();
        })
        .catch((error) => {
          Warn("Unable to save Task");
          this.loading = false;
        });
    },
    saveTasks() {
      this.loading = true;
      if (this.isEdit) {
        return this.updateTask(this.data);
      }
      return this.createTask();
    },
  },
  created() {
    this.setDetails();
    if (!this.toolsLoaded) {
      this.loadingTools = true;
      this.loadToolsAction()
        .then(() => {
          Success("Tools Loaded");
          this.loadingTools = false;
        })
        .catch((error) => {
          Warn("Unable to Load Tools");
          this.loadingTools = false;
        });
    }
  },
});
