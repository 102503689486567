
import Vue from "vue";
import { mapMutations, mapActions } from "vuex";
import { CustomerMutationTypes } from "@/store/customers/mutation-types";
import { CustomerActionTypes } from "@/store/customers/action-types";
import { countryFunctions } from "@/services/country-helper";
import { Info, Success, Warn } from "@/services/notif-utils";
import api from "@/api/shipping-api-cors/index";
export default Vue.extend({
  name: "CustomerModal",
  props: ["show", "customerData"],
  data() {
    return {
      loading: false,
      data: {
        id: null,
        discogs_username: "",
        discogs_email: "",
        ebay_username: "",
        kupiku_username: "",
        kupiku_email: "",
        is_vip: true,
        country: "",
        tasks: [],
      },
      countrySelected: "",
      countries: countryFunctions.getCountryNames(),
      isEdit: false,
      loadingTasks: false,
    };
  },
  computed: {
    tasks(): any {
      return this.$store.state.Customer.tasks;
    },
    tasksLoaded(): any {
      return this.$store.state.Customer.tasksLoaded;
    },
    toggleState(): boolean {
      return this.show;
    },
    getVipLabel(): string {
      if (this.data["is_vip"]) {
        return "VIP!";
      }
      return "Not VIP";
    },
  },
  methods: {
    ...mapMutations({
      updateCustomerMutation:
        "Customer/" + CustomerMutationTypes.UPDATE_CUSTOMERS,
      addCustomerMutation: "Customer/" + CustomerMutationTypes.ADD_CUSTOMER,
    }),
    ...mapActions({
      saveCustomerAction: "Customer/" + CustomerActionTypes.CREATE_CUSTOMER,
      loadTasksAction: "Customer/" + CustomerActionTypes.LOAD_TASKS,
      updateCustomerAction: "Customer/" + CustomerActionTypes.UPDATE_CUSTOMER,
    }),
    parseTaskListFromObject(tasks: any) {
      const newList = [] as any;
      tasks.forEach((element: any) => {
        return newList.push(element.id);
      });
      return newList;
    },
    setDetails() {
      if (this.customerData) {
        console.log("Setting Customer Details");
        this.isEdit = true;
        const loadedCustomer = this.customerData;
        this.data = {
          id: loadedCustomer["id"],
          discogs_username: loadedCustomer["discogs_username"],
          discogs_email: loadedCustomer["discogs_email"],
          ebay_username: loadedCustomer["ebay_username"],
          kupiku_username: loadedCustomer["kupiku_username"],
          kupiku_email: loadedCustomer["kupiku_email"],
          country: loadedCustomer["country"],
          is_vip: loadedCustomer["is_vip"],
          tasks: this.parseTaskListFromObject(loadedCustomer["tasks"]),
        };
      }
    },
    filterFn(val: any, update: any, abort: any) {
      update(() => {
        const needle = val.toLowerCase();
        const names: string[] = countryFunctions.getCountryNames();
        this.countries = names.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    close() {
      this.$emit("close");
    },
    reset() {
      return (this.data = {
        id: null,
        discogs_username: "",
        discogs_email: "",
        ebay_username: "",
        kupiku_username: "",
        kupiku_email: "",
        is_vip: true,
        country: "",
        tasks: [],
      });
    },
    updateCustomer() {
      this.updateCustomerAction(this.data)
        .then(() => {
          Success("Updated Customer!");
          this.loading = false;
          this.reset();
          this.close();
        })
        .catch((error) => {
          Warn("Unable to Update Customer.");
          this.loading = false;
        });
    },
    saveCustomer() {
      this.loading = true;
      if (this.isEdit) {
        return this.updateCustomer();
      }
      api
        .createCustomer(this.data)
        .then((response) => {
          this.addCustomerMutation(this.data);
          if (this.isEdit) {
            this.updateCustomerMutation(this.data);
          }
          Success("Customer Saved!");
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          Warn("Save Unsuccessful");
          this.loading = false;
        });
    },
  },
  created() {
    this.setDetails();
    if (!this.tasksLoaded) {
      this.loadingTasks = true;
      this.loadTasksAction()
        .then((response) => {
          this.loadingTasks = false;
        })
        .catch(() => {
          this.loadingTasks = false;
        });
    }
  },
});
